@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

:root {
  --primary-color: #4a6cfa;
  --primary-light: #eef1ff;
  --primary-dark: #3950b3;
  --accent-color: #ff6b6b;
  --background-color: #f8f9fa;
  --card-background: #ffffff;
  --text-primary: #333333;
  --text-secondary: #666666;
  --text-light: #999999;
  --border-color: #eeeeee;
  --success-color: #4caf50;
  --warning-color: #ff9800;
  --error-color: #f44336;
  --border-radius-sm: 4px;
  --border-radius-md: 8px;
  --border-radius-lg: 12px;
  --box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  --box-shadow-hover: 0 10px 15px rgba(0, 0, 0, 0.1);
  --transition: all 0.3s ease;
  --header-height: 60px;
  --tabbar-height: 60px;
  --drawer-width: 320px;
  --font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  --max-width: 1280px;
  --side-padding: 16px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: var(--font-family);
  color: var(--text-primary);
  background-color: var(--background-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}

/* App Wrapper - Main container for the entire application */
.app-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  overflow-x: hidden;
}

/* App Container */
.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  width: 100%;
}

/* Main Content */
.main-content {
  flex: 1;
  padding-top: calc(var(--header-height) + 16px);
  padding-bottom: calc(var(--tabbar-height) + 16px);
  background-color: var(--background-color);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  width: 100%;
}

.content-container {
  padding: 0 var(--side-padding);
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
}

/* Header */
.header-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  background-color: var(--card-background);
  box-shadow: var(--box-shadow);
}

.header-top {
  height: var(--header-height);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 var(--side-padding);
  max-width: var(--max-width);
  margin: 0 auto;
  width: 100%;
}

.header-title {
  font-size: 1.2rem;
  font-weight: 600;
  text-align: center;
  flex: 1;
  color: var(--text-primary);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 16px;
}

.header-right-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.header-button {
  background: transparent;
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-primary);
  position: relative;
  cursor: pointer;
  transition: var(--transition);
}

.header-button:hover, .header-button:focus {
  background-color: var(--primary-light);
  color: var(--primary-color);
}

.header-button:active {
  transform: scale(0.95);
}

.cart-button {
  position: relative;
}

.header-badge {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: var(--accent-color);
  color: white;
  font-size: 0.7rem;
  min-width: 18px;
  height: 18px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

/* Search Bar */
.search-bar-container {
  padding: 8px var(--side-padding) 16px;
  background-color: var(--card-background);
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow: hidden;
  max-width: var(--max-width);
  margin: 0 auto;
  width: 100%;
}

.search-input-container {
  display: flex;
  align-items: center;
  background-color: var(--background-color);
  border-radius: var(--border-radius-lg);
  padding: 0 12px;
  position: relative;
  border: 1px solid var(--border-color);
}

.search-input-container:focus-within {
  border-color: var(--primary-color);
  box-shadow: 0 0 0 2px rgba(74, 108, 250, 0.1);
}

.search-icon {
  color: var(--text-light);
  margin-right: 8px;
}

.search-input {
  flex: 1;
  height: 44px;
  border: none;
  background: transparent;
  padding: 0;
  font-size: 1rem;
  color: var(--text-primary);
  width: 100%;
  outline: none;
}

.search-input::placeholder {
  color: var(--text-light);
}

.clear-button {
  background: transparent;
  border: none;
  color: var(--text-light);
  padding: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-submit-button {
  height: 44px;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: var(--border-radius-md);
  font-weight: 600;
  cursor: pointer;
  transition: var(--transition);
  padding: 0 20px;
}

.search-submit-button:disabled {
  background-color: var(--text-light);
  cursor: not-allowed;
}

.search-submit-button:enabled:hover {
  background-color: var(--primary-dark);
}

/* Tab Bar */
.tab-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: var(--tabbar-height);
  display: flex;
  background-color: var(--card-background);
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.05);
  z-index: 90;
}

.tab-item {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--text-secondary);
  padding: 8px 0;
  position: relative;
  cursor: pointer;
  transition: var(--transition);
}

.tab-item.active {
  color: var(--primary-color);
}

.tab-item.active::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 32px;
  height: 3px;
  background-color: var(--primary-color);
  border-radius: 1.5px;
}

.tab-icon, .tab-icon-container {
  font-size: 1.2rem;
  margin-bottom: 4px;
  position: relative;
}

.tab-label {
  font-size: 0.75rem;
  font-weight: 500;
}

.tab-badge {
  position: absolute;
  top: -5px;
  right: -8px;
  background-color: var(--accent-color);
  color: white;
  font-size: 0.6rem;
  min-width: 16px;
  height: 16px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

/* Drawer */
.drawer-container {
  position: fixed;
  top: 0;
  left: 0;
  width: var(--drawer-width);
  max-width: 85vw;
  height: 100vh;
  background-color: var(--card-background);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  box-shadow: 5px 0 15px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.drawer-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  backdrop-filter: blur(2px);
}

.drawer-header {
  padding: 24px 16px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background-color: var(--primary-light);
  border-bottom: 1px solid var(--border-color);
}

.drawer-logo-container {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  box-shadow: var(--box-shadow);
  margin-bottom: 12px;
}

.app-logo {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.app-logo-placeholder {
  color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.welcome-text {
  font-size: 1rem;
  font-weight: 600;
  color: var(--primary-color);
  text-align: center;
  margin: 0;
}

.drawer-close-button {
  position: absolute;
  top: 16px;
  right: 16px;
  background: transparent;
  border: none;
  color: var(--text-secondary);
  cursor: pointer;
  padding: 8px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.drawer-close-button:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.drawer-content {
  flex: 1;
  overflow-y: auto;
  padding: 16px 0;
}

.drawer-section {
  margin-bottom: 16px;
}

.drawer-section-title {
  padding: 0 16px;
  font-size: 0.85rem;
  font-weight: 600;
  color: var(--text-light);
  margin-bottom: 8px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.drawer-item {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  cursor: pointer;
  transition: var(--transition);
  position: relative;
}

.drawer-item:hover, .drawer-item:active {
  background-color: var(--primary-light);
}

.drawer-item-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin-right: 16px;
  color: var(--text-secondary);
}

.drawer-item-text {
  flex: 1;
  font-size: 0.9rem;
  color: var(--text-primary);
}

.drawer-item-badge {
  background-color: var(--accent-color);
  color: white;
  min-width: 20px;
  height: 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
  font-weight: bold;
  padding: 0 6px;
}

.drawer-divider {
  height: 1px;
  background-color: var(--border-color);
  margin: 8px 0;
}

.social-links-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
  padding: 8px 16px;
}

.social-link-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 12px 8px;
  border-radius: var(--border-radius-md);
  background-color: var(--primary-light);
  cursor: pointer;
  transition: var(--transition);
}

.social-link-item:hover {
  background-color: var(--primary-color);
  color: white;
}

.social-icon {
  width: 24px;
  height: 24px;
  margin-bottom: 4px;
  object-fit: contain;
}

.social-link-name {
  font-size: 0.7rem;
  text-align: center;
}

.drawer-footer {
  padding: 16px;
  text-align: center;
  border-top: 1px solid var(--border-color);
}

.drawer-footer-text {
  font-size: 0.8rem;
  color: var(--text-light);
}

.drawer-loading-container {
  justify-content: center;
  align-items: center;
}

.drawer-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 3px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top-color: var(--primary-color);
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

/* Toast Notifications */
.Toastify__toast-container {
  width: 100%;
  max-width: var(--max-width);
  margin: 0 auto;
  padding: 0 var(--side-padding);
  bottom: calc(var(--tabbar-height) + 16px);
}

.Toastify__toast {
  border-radius: var(--border-radius-md);
  padding: 12px 16px;
  box-shadow: var(--box-shadow);
  font-family: var(--font-family);
  margin-bottom: 8px;
}

.Toastify__toast--success {
  background-color: var(--success-color);
}

.Toastify__toast--warning {
  background-color: var(--warning-color);
}

.Toastify__toast--error {
  background-color: var(--error-color);
}

.Toastify__toast-body {
  padding: 0;
  margin: 0;
}

/* Responsive adjustments */
@media (min-width: 768px) {
  :root {
    --side-padding: 24px;
    --header-height: 70px;
  }

  .header-title {
    font-size: 1.4rem;
  }

  .content-container {
    max-width: var(--max-width);
  }

  .search-bar-container {
    padding: 16px var(--side-padding);
  }

  .tab-label {
    font-size: 0.85rem;
  }
}

@media (min-width: 1024px) {
  :root {
    --drawer-width: 360px;
  }

  .main-content {
    padding-left: 0;
    padding-right: 0;
  }
}

/* Animation classes */
.fade-in {
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.slide-up {
  animation: slideUp 0.3s ease-in-out;
}

@keyframes slideUp {
  from { transform: translateY(20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

/* Utility classes */
.text-center {
  text-align: center;
}

.text-primary {
  color: var(--primary-color);
}

.text-secondary {
  color: var(--text-secondary);
}

.text-light {
  color: var(--text-light);
}

.bg-primary {
  background-color: var(--primary-color);
}

.bg-light {
  background-color: var(--primary-light);
}

.mt-1 { margin-top: 4px; }
.mt-2 { margin-top: 8px; }
.mt-3 { margin-top: 16px; }
.mt-4 { margin-top: 24px; }
.mt-5 { margin-top: 32px; }

.mb-1 { margin-bottom: 4px; }
.mb-2 { margin-bottom: 8px; }
.mb-3 { margin-bottom: 16px; }
.mb-4 { margin-bottom: 24px; }
.mb-5 { margin-bottom: 32px; }

.p-1 { padding: 4px; }
.p-2 { padding: 8px; }
.p-3 { padding: 16px; }
.p-4 { padding: 24px; }
.p-5 { padding: 32px; }

.rounded-sm { border-radius: var(--border-radius-sm); }
.rounded-md { border-radius: var(--border-radius-md); }
.rounded-lg { border-radius: var(--border-radius-lg); }

.shadow {
  box-shadow: var(--box-shadow);
}

.shadow-hover:hover {
  box-shadow: var(--box-shadow-hover);
}

/* Accessibility */
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

/* Custom scrollbar */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: var(--background-color);
}

::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--primary-dark);
}